<template>
  <v-menu rounded width="250">
    <template #activator="{ props }">
      <div
        icon
        v-bind="props"
        class="user-menu-btn"
        :class="{
          'user-menu-btn__size--default': !smAndDown,
          'user-menu-btn__size--small': smAndDown,
        }"
        variant="text">
        <UserAvatar size="small" />
        <div v-if="isPersonal" class="user-menu-title-container">
          <div class="user-menu-title-text" style="margin-left: 8px" v-if="!smAndDown">
            {{ t('userMenu.personal') }}
          </div>
        </div>
        <div v-if="activeOrganizationId" class="user-menu-title-container">
          <v-icon
            :icon="'mdi-office-building'"
            class="title-icon"
            color="black"
            style="margin-left: 4px"></v-icon>

          <v-list-item-title class="user-menu-title-text">
            {{ organization?.name || t('userMenu.organizational') }}
          </v-list-item-title>
        </div>
        <v-icon icon="mdi-chevron-down" size="xsmall" />
      </div>
    </template>
    <v-card>
      <UserMenuHeader avatar-size="default">
        <template #append>
          <v-list-item-title class="user-menu-card-text-caption">{{ email }}</v-list-item-title>
        </template>
      </UserMenuHeader>
      <v-list density="compact">
        <v-list-item
          :aria-disabled="isChatLoading"
          :disabled="isChatLoading"
          key="language"
          value="language"
          color="primary"
          variant="plain"
          class="no-opacity">
          <template #prepend>
            <v-icon
              :icon="'mdi-web'"
              style="margin-right: -24px"
              :color="isChatLoading ? 'grey' : 'black'"></v-icon>
          </template>
          <InlineSelect
            :disabled="isChatLoading"
            v-model="userProfile.country"
            @click.stop
            item-title="label"
            item-value="value"
            :items="langOptions"
            :placeholder="t('global.select')"
            :hide-details="false" />
        </v-list-item>

        <AccountSwitcher v-if="isSuperAdmin || isOrganizationMember" />
        <OrganizationalMenuItems
          v-if="(isSuperAdmin || isOrganizationAdmin) && activeOrganizationId" />

        <SuperAdminMenuItems v-if="isSuperAdmin" />

        <v-divider :thickness="1" style="opacity: 80%" class="my-3" />
        <RouterLink class="link" :to="{ name: 'UserProfile' }">
          <v-list-item key="my-profile" value="my-profile" color="primary" variant="text">
            <template #prepend>
              <v-icon :icon="'mdi-account'" style="margin-right: -24px" color="black"></v-icon>
            </template>
            <v-list-item-title>
              {{ t('myProfile.title') }}
            </v-list-item-title>
          </v-list-item>
        </RouterLink>
        <RouterLink class="link" :to="{ name: 'Logout' }">
          <v-list-item key="logout" value="logout" color="primary" variant="text">
            <template #prepend>
              <v-icon :icon="'mdi-logout'" style="margin-right: -24px" color="black"></v-icon>
            </template>
            <v-list-item-title>{{ t('global.logout') }}</v-list-item-title>
          </v-list-item>
        </RouterLink>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup>
import { useDisplay } from 'vuetify';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import InlineSelect from '@/components/form/InlineSelect.vue';
import SuperAdminMenuItems from '@/features/Header/components/SuperAdminMenuItems.vue';
import OrganizationalMenuItems from '@/features/Header/components/OrganizationalMenuItems.vue';
import UserMenuHeader from '@/features/Header/components/UserMenuHeader.vue';
import AccountSwitcher from '@/features/Header/components/AccountSwitcher.vue';
import { userInterfaceLanguageOptions } from '@/composables/userInterfaceLanguageOptions';
import UserAvatar from '@/features/Header/components/UserAvatar.vue';

const { t } = useI18n();
const store = useStore();
const { smAndDown } = useDisplay();

const userProfile = computed(() => store.state.userProfile);
const isSuperAdmin = computed(() => store.getters.isSuperAdmin);
const isOrganizationAdmin = computed(() => store.getters.isOrganizationAdmin);
const isOrganizationMember = computed(() => store.getters.isOrganizationMember);
const organization = computed(() => store.state.organization);
const locale = computed(() => store.state.locale);
const isChatLoading = computed(() => store.state.chatLoading);

const isPersonal = computed(() => !organization.value);

const email = computed(() => store.state.userProfile?.email);
const activeOrganizationId = computed(() => store.state.organization?.id);

const langOptions = userInterfaceLanguageOptions();

watch(
  () => store.state.userProfile,
  async (newValue) => {
    if (newValue.country !== locale.value) {
      try {
        await store.dispatch('updateUserProfile', userProfile.value);

        store.commit('SET_STATE_PROPERTY', { property: 'locale', value: newValue.country });
        localStorage.setItem('selectedLocale', newValue.country);

        store.dispatch('trackEvent', {
          event: 'UserProfileUpdated',
          values: {
            content: {
              uid: userProfile.value.uid,
              country: newValue.country || 'Other',
            },
          },
        });
        store.commit('SET_STATE_PROPERTY', { property: 'stayInChat', value: true });
      } catch (error) {
        console.error('error', error);
      }
    }
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
.user-menu-title-text {
  font-size: rem(14px);
  text-transform: capitalize;
  font-weight: 500;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
}
.title-icon {
  font-size: rem(14px);
}
.user-menu-btn {
  display: flex;
  flex-direction: row;
  gap: 2px;
  height: 54px;
  align-items: center;
  cursor: pointer;
  padding: 0px 16px;

  &:hover {
    background-color: #f6f6f7;
  }
  &:focus {
    background-color: #d7d9de;
  }
  &__size--small {
    width: 84px;
  }
  &__size--default {
    width: 172px;
  }
}

.link {
  text-decoration: none;
  color: inherit;
}

.user-menu-title-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 10px;
  max-width: 100%;
  overflow: hidden;
}
.inner-initials {
  font-size: rem(10px);
}
.user-menu-subheader {
  text-transform: uppercase;
  font-size: rem(10px);
}
.no-opacity {
  opacity: 1;
}

.user-menu-card-text-caption {
  @include textSubtitle;
}
</style>
