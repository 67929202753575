<template>
  <BaseModal v-model="model" :can-close="false">
    <UserProfileForm @submit:user-profile="handleSubmit" />
  </BaseModal>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { useStore } from 'vuex';

import BaseModal from '@/components/BaseModal.vue';
import UserProfileForm from '@/features/Settings/components/Content/components/UserProfileForm.vue';

const store = useStore();
const router = useRouter();
const redirectedUrl = computed(() => store.state.newUserAuthRedirectedUrl);

const model = computed({
  get: () => store.state.userProfileModal,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', {
      property: 'userProfileModal',
      value: val,
    });
  },
});

const handleSubmit = () => {
  model.value = false;
  router.push(redirectedUrl.value);
};
</script>
